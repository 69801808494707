import { createRouter, createWebHistory } from "vue-router";
import Main from "../pages/Main/Main.vue";

const routes = [
    {
        path: "/",
        name: "default",
        redirect: { name: "search" },
    },
    {
        path: "/search",
        component: Main,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
