<template>
    <div class="item-list-root">
        <div v-if="manuals" class="item-list-wrapper grid">
            <item-tile
                v-for="(manual, index) in manuals"
                :key="index"
                :data="manual"
                @tileClick="onTileClick"
                class="item-tile-outer-wrapper"
                :class="{
                    'col-4': mediaQuery.isDesktop || mediaQuery.isLaptop,
                    'col-6': mediaQuery.isTablet,
                    'col-12': mediaQuery.isMobile,
                }"
            />
        </div>
    </div>
</template>

<script>
import ItemTile from "../itemTile/ItemTile.vue";

export default {
    name: "itemList",
    props: ["manuals"],
    components: { ItemTile },
    mounted() {},
    methods: {
        onTileClick(manual) {
            this.$emit("manualClicked", manual);
        },
    },
};
</script>

<style>
.item-tile-outer-wrapper {
    height: 140px;
}
</style>
