<template>
    <div class="filter-bar-root">
        <div v-if="currentFilterData" class="filter-bar-wrapper">
            <span
                class="filter-bar-wrapper"
                v-for="(filter, index) in currentFilterData"
                :key="index"
            >
                <MultiSelect
                    class="filter-bar-menu"
                    :ref="`filterBarMenu`"
                    v-model="currentFilters[filter.var]"
                    :options="filter.data"
                    :placeholder="filter.name"
                    :showToggleAll="false"
                    :filter="true"
                    optionLabel="key"
                    @change="onChange"
                >
                    <template #value>
                        {{ filter.name }}
                    </template>
                    <template #option="slotProps">
                        <span class="filter-option">
                            {{ slotProps.option.key }} ({{
                                slotProps.option.doc_count
                            }})
                        </span>
                    </template>
                </MultiSelect>
            </span>
        </div>
        <Transition
            :name="showDetails ? 'fadeSingle2' : 'fadeSingle'"
            mode="out-in"
        >
            <div
                v-if="
                    currentFilters &&
                        currentFilterData &&
                        currentFiltersHasContent
                "
                class="filter-list-outer-wrapper"
            >
                <span
                    v-for="(filterList, index) in currentFilters"
                    :key="index"
                    class="filter-list-wrapper"
                >
                    <span
                        v-for="(filter, idx) in filterList"
                        :key="idx"
                        class="filter-tag-wrapper"
                    >
                        <span class="filter-tag-name">{{
                            getFilterListName(currentFilterData, index)
                        }}</span>
                        <span class="filter-tag-seperator">:</span>
                        <span class="filter-tag-value">{{ filter.key }}</span>
                        <span class="filter-tag-close"
                            ><i
                                class="pi pi-times-circle"
                                @click="onFilterTagClick(idx, index)"
                            ></i
                        ></span>
                    </span>
                </span>
            </div>
        </Transition>
    </div>
</template>

<script>
import MultiSelect from "primevue/multiselect";

export default {
    name: "itemFilters",
    props: ["filterData", "currentlySelected"],
    components: {
        MultiSelect,
    },
    data() {
        return {
            currentFilters: null,
            currentFilterData: null,
        };
    },
    mounted() {
        this.currentFilterData = this.filterData;
        this.currentFilters = this.currentlySelectedFormatted;

        console.log("fd", this.currentFilters, this.filterData);
    },
    computed: {
        currentlySelectedFormatted() {
            if (this.currentlySelected && this.currentFilterData) {
                const cs = { ...this.currentlySelected };
                const keys = Object.keys(cs);
                if (keys.length > 0) {
                    for (const i in keys) {
                        const key = keys[i];
                        const arr = this.currentFilterData.find(
                            el => el.var == key
                        );
                        for (const j in cs[key]) {
                            const oldEl = cs[key][j];
                            const newEl = arr.data.find(
                                el => el.key == oldEl.key
                            );
                            if (newEl && newEl != undefined) cs[key][j] = newEl;
                        }
                    }
                }
                return cs;
            }

            return this.null;
        },
        currentFiltersHasContent() {
            if (this.currentFilters) {
                for (const i in this.currentFilters) {
                    if (this.currentFilters[i].length > 0) return true;
                }
            }

            return false;
        },
    },
    watch: {
        currentlySelected(nv, ov) {
            this.currentFilters = nv;
        },
        filterData(nv) {
            this.currentFilterData = nv;
        },
    },
    methods: {
        onFilterTagClick(idx, index) {
            const filters = { ...this.currentFilters };
            filters[index].splice(idx);
            if (filters[index].length == 0) delete filters[index];
            this.currentFilters = filters;
            this.onChange();
        },
        onChange() {
            // const filters = {}
            // for(let i in this.currentFilters) filters[i] = this.currentFilters[i].map(el => el.key)
            for (const i in this.$refs.filterBarMenu)
                this.$refs.filterBarMenu[i].hide();
            this.$emit("filterChanged", this.currentFilters);
        },
        getFilterListName(list, listVar) {
            if (list) {
                const listEl = list.find(el => el.var == listVar);
                if (listEl) {
                    return listEl.name;
                }
            }
            return null;
        },
    },
};
</script>

<style scoped>
.filter-bar-root {
    padding: 0px 0px 20px;
}

.filter-wrapper {
    display: inline-flex;
}

.filter-bar-menu {
    display: inline-flex;
    margin-right: 10px;
}

.filter-list-outer-wrapper {
    margin: 10px 0px;
}

.filter-tag-wrapper {
    padding: 4px 10px;
    background-color: #c5c5c5;
    border-radius: 15px;
    margin-right: 5px;
}

.filter-tag-seperator {
    margin-right: 6px;
}

.filter-tag-close {
    margin: 4px 0px 0px 5px;
}

.filter-tag-close i {
    vertical-align: text-bottom;
    cursor: pointer;
}
</style>
