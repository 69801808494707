import * as _pdf from "pdfjs-dist/lib/pdf";
import { PDFViewerApplication } from "./edited_app";

/**
 * @licstart The following is the entire license notice for the
 * Javascript code in this page
 *
 * Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * @licend The above is the entire license notice for the
 * Javascript code in this page
 */
("use strict");


const _preferences = require("pdfjs-dist/lib/web/preferences.js");

const _download_manager = require("pdfjs-dist/lib/web/download_manager.js");

const _generic_scripting = require("pdfjs-dist/lib/web/generic_scripting.js");
const _genericl10n = require("./genericl10n.js");

const _app = require("./edited_app.js");

const GenericCom = {};

class GenericPreferences extends _preferences.BasePreferences {
    async _writeToStorage(prefObj) {
        localStorage.setItem("pdfjs.preferences", JSON.stringify(prefObj));
    }

    async _readFromStorage(prefObj) {
        return JSON.parse(localStorage.getItem("pdfjs.preferences"));
    }
}

class GenericExternalServices extends _app.DefaultExternalServices {
    static createDownloadManager(options) {
        return new _download_manager.DownloadManager();
    }

    static createPreferences() {
        return new GenericPreferences();
    }

    static createL10n({ locale = "en-US" }) {
        return new _genericl10n.GenericL10n(locale);
    }

    static createScripting({ sandboxBundleSrc }) {
        return new _generic_scripting.GenericScripting(sandboxBundleSrc);
    }
}

PDFViewerApplication.externalServices = GenericExternalServices;
export { GenericExternalServices, GenericCom };
