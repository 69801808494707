<template>
    <div class="footer-root">
        FOOTER
    </div>
</template>

<script>
export default {
    name: "searchables-footer",
    props: ["mobile"],
};
</script>

<style></style>
