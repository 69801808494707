<template>
    <div class="pdf-viewer-root p-shadow-2 p-mb-4">
        <div
            v-if="fileUrl"
            ref="pdfOuterContainer"
            id="outerContainer"
            class="pdf-viewer-container"
        >
            <div id="sidebarContainer">
                <div id="toolbarSidebar">
                    <div id="toolbarSidebarLeft">
                        <div class="splitToolbarButton toggled">
                            <button
                                id="viewThumbnail"
                                class="toolbarButton toggled"
                                title="Show Thumbnails"
                                tabindex="2"
                                data-l10n-id="thumbs"
                            >
                                <span data-l10n-id="thumbs_label"
                                    >Thumbnails</span
                                >
                            </button>
                            <button
                                id="viewOutline"
                                class="toolbarButton"
                                title="Show Document Outline (double-click to expand/collapse all items)"
                                tabindex="3"
                                data-l10n-id="document_outline"
                            >
                                <span data-l10n-id="document_outline_label"
                                    >Document Outline</span
                                >
                            </button>
                            <button
                                style="display: none;"
                                id="viewAttachments"
                                class="toolbarButton"
                                title="Show Attachments"
                                tabindex="4"
                                data-l10n-id="attachments"
                            >
                                <span data-l10n-id="attachments_label"
                                    >Attachments</span
                                >
                            </button>
                            <button
                                style="display: none;"
                                id="viewLayers"
                                class="toolbarButton"
                                title="Show Layers (double-click to reset all layers to the default state)"
                                tabindex="5"
                                data-l10n-id="layers"
                            >
                                <span data-l10n-id="layers_label">Layers</span>
                            </button>
                        </div>
                    </div>

                    <div id="toolbarSidebarRight">
                        <div id="outlineOptionsContainer" class="hidden">
                            <div class="verticalToolbarSeparator"></div>

                            <button
                                id="currentOutlineItem"
                                class="toolbarButton"
                                disabled="disabled"
                                title="Find Current Outline Item"
                                tabindex="6"
                                data-l10n-id="current_outline_item"
                            >
                                <span data-l10n-id="current_outline_item_label"
                                    >Current Outline Item</span
                                >
                            </button>
                        </div>
                    </div>
                </div>
                <div id="sidebarContent">
                    <div id="thumbnailView"></div>
                    <div id="outlineView" class="hidden"></div>
                    <div id="attachmentsView" class="hidden"></div>
                    <div id="layersView" class="hidden"></div>
                </div>
                <div id="sidebarResizer"></div>
            </div>
            <!-- sidebarContainer -->

            <div id="mainContainer">
                <div class="findbar hidden doorHanger" id="findbar">
                    <div id="findbarInputContainer">
                        <input
                            id="findInput"
                            class="toolbarField"
                            title="Find"
                            placeholder="Find in document…"
                            tabindex="91"
                            data-l10n-id="find_input"
                        />
                        <div class="splitToolbarButton">
                            <button
                                id="findPrevious"
                                class="toolbarButton findPrevious"
                                title="Find the previous occurrence of the phrase"
                                tabindex="92"
                                data-l10n-id="find_previous"
                            >
                                <span data-l10n-id="find_previous_label"
                                    >Previous</span
                                >
                            </button>
                            <div class="splitToolbarButtonSeparator"></div>
                            <button
                                id="findNext"
                                class="toolbarButton findNext"
                                title="Find the next occurrence of the phrase"
                                tabindex="93"
                                data-l10n-id="find_next"
                            >
                                <span data-l10n-id="find_next_label">Next</span>
                            </button>
                        </div>
                    </div>

                    <div id="findbarOptionsOneContainer">
                        <input
                            type="checkbox"
                            id="findHighlightAll"
                            class="toolbarField"
                            tabindex="94"
                        />
                        <label
                            for="findHighlightAll"
                            class="toolbarLabel"
                            data-l10n-id="find_highlight"
                            >Highlight all</label
                        >
                        <input
                            type="checkbox"
                            id="findMatchCase"
                            class="toolbarField"
                            tabindex="95"
                        />
                        <label
                            for="findMatchCase"
                            class="toolbarLabel"
                            data-l10n-id="find_match_case_label"
                            >Match case</label
                        >
                    </div>
                    <div id="findbarOptionsTwoContainer">
                        <input
                            type="checkbox"
                            id="findEntireWord"
                            class="toolbarField"
                            tabindex="96"
                        />
                        <label
                            for="findEntireWord"
                            class="toolbarLabel"
                            data-l10n-id="find_entire_word_label"
                            >Whole words</label
                        >
                        <span
                            id="findResultsCount"
                            class="toolbarLabel hidden"
                        ></span>
                    </div>

                    <div id="findbarMessageContainer">
                        <span id="findMsg" class="toolbarLabel"></span>
                    </div>
                </div>
                <!-- findbar -->

                <div
                    id="secondaryToolbar"
                    class="secondaryToolbar hidden doorHangerRight"
                >
                    <div id="secondaryToolbarButtonContainer">
                        <button
                            id="secondaryPresentationMode"
                            class="secondaryToolbarButton presentationMode visibleLargeView"
                            title="Full Screen"
                            tabindex="51"
                            data-l10n-id="presentation_mode"
                        >
                            <span data-l10n-id="presentation_mode_label"
                                >Full Screen</span
                            >
                        </button>

                        <button
                            id="secondaryOpenFile"
                            class="secondaryToolbarButton openFile visibleLargeView"
                            title="Open File"
                            tabindex="52"
                            data-l10n-id="open_file"
                        >
                            <span data-l10n-id="open_file_label">Open</span>
                        </button>

                        <button
                            id="secondaryPrint"
                            class="secondaryToolbarButton print visibleMediumView"
                            title="Print"
                            tabindex="53"
                            data-l10n-id="print"
                        >
                            <span data-l10n-id="print_label">Print</span>
                        </button>

                        <button
                            id="secondaryDownload"
                            class="secondaryToolbarButton download visibleMediumView"
                            title="Download"
                            tabindex="54"
                            data-l10n-id="download"
                        >
                            <span data-l10n-id="download_label">Download</span>
                        </button>

                        <a
                            href="#"
                            id="secondaryViewBookmark"
                            class="secondaryToolbarButton bookmark visibleSmallView"
                            title="Current view (copy or open in new window)"
                            tabindex="55"
                            data-l10n-id="bookmark"
                        >
                            <span data-l10n-id="bookmark_label"
                                >Current View</span
                            >
                        </a>

                        <div
                            class="horizontalToolbarSeparator visibleLargeView"
                        ></div>

                        <button
                            id="firstPage"
                            class="secondaryToolbarButton firstPage"
                            title="Go to First Page"
                            tabindex="56"
                            data-l10n-id="first_page"
                        >
                            <span data-l10n-id="first_page_label"
                                >Go to First Page</span
                            >
                        </button>
                        <button
                            id="lastPage"
                            class="secondaryToolbarButton lastPage"
                            title="Go to Last Page"
                            tabindex="57"
                            data-l10n-id="last_page"
                        >
                            <span data-l10n-id="last_page_label"
                                >Go to Last Page</span
                            >
                        </button>

                        <div class="horizontalToolbarSeparator"></div>

                        <button
                            id="pageRotateCw"
                            class="secondaryToolbarButton rotateCw"
                            title="Rotate Clockwise"
                            tabindex="58"
                            data-l10n-id="page_rotate_cw"
                        >
                            <span data-l10n-id="page_rotate_cw_label"
                                >Rotate Clockwise</span
                            >
                        </button>
                        <button
                            id="pageRotateCcw"
                            class="secondaryToolbarButton rotateCcw"
                            title="Rotate Counterclockwise"
                            tabindex="59"
                            data-l10n-id="page_rotate_ccw"
                        >
                            <span data-l10n-id="page_rotate_ccw_label"
                                >Rotate Counterclockwise</span
                            >
                        </button>

                        <div class="horizontalToolbarSeparator"></div>

                        <button
                            id="cursorSelectTool"
                            class="secondaryToolbarButton selectTool toggled"
                            title="Enable Text Selection Tool"
                            tabindex="60"
                            data-l10n-id="cursor_text_select_tool"
                        >
                            <span data-l10n-id="cursor_text_select_tool_label"
                                >Text Selection Tool</span
                            >
                        </button>
                        <button
                            id="cursorHandTool"
                            class="secondaryToolbarButton handTool"
                            title="Enable Hand Tool"
                            tabindex="61"
                            data-l10n-id="cursor_hand_tool"
                        >
                            <span data-l10n-id="cursor_hand_tool_label"
                                >Hand Tool</span
                            >
                        </button>

                        <div class="horizontalToolbarSeparator"></div>

                        <button
                            id="scrollVertical"
                            class="secondaryToolbarButton scrollModeButtons scrollVertical toggled"
                            title="Use Vertical Scrolling"
                            tabindex="62"
                            data-l10n-id="scroll_vertical"
                        >
                            <span data-l10n-id="scroll_vertical_label"
                                >Vertical Scrolling</span
                            >
                        </button>
                        <button
                            id="scrollHorizontal"
                            class="secondaryToolbarButton scrollModeButtons scrollHorizontal"
                            title="Use Horizontal Scrolling"
                            tabindex="63"
                            data-l10n-id="scroll_horizontal"
                        >
                            <span data-l10n-id="scroll_horizontal_label"
                                >Horizontal Scrolling</span
                            >
                        </button>
                        <button
                            id="scrollWrapped"
                            class="secondaryToolbarButton scrollModeButtons scrollWrapped"
                            title="Use Wrapped Scrolling"
                            tabindex="64"
                            data-l10n-id="scroll_wrapped"
                        >
                            <span data-l10n-id="scroll_wrapped_label"
                                >Wrapped Scrolling</span
                            >
                        </button>

                        <div
                            class="horizontalToolbarSeparator scrollModeButtons"
                        ></div>

                        <button
                            id="spreadNone"
                            class="secondaryToolbarButton spreadModeButtons spreadNone toggled"
                            title="Do not join page spreads"
                            tabindex="65"
                            data-l10n-id="spread_none"
                        >
                            <span data-l10n-id="spread_none_label"
                                >No Spreads</span
                            >
                        </button>
                        <button
                            id="spreadOdd"
                            class="secondaryToolbarButton spreadModeButtons spreadOdd"
                            title="Join page spreads starting with odd-numbered pages"
                            tabindex="66"
                            data-l10n-id="spread_odd"
                        >
                            <span data-l10n-id="spread_odd_label"
                                >Odd Spreads</span
                            >
                        </button>
                        <button
                            id="spreadEven"
                            class="secondaryToolbarButton spreadModeButtons spreadEven"
                            title="Join page spreads starting with even-numbered pages"
                            tabindex="67"
                            data-l10n-id="spread_even"
                        >
                            <span data-l10n-id="spread_even_label"
                                >Even Spreads</span
                            >
                        </button>

                        <div
                            class="horizontalToolbarSeparator spreadModeButtons"
                        ></div>

                        <button
                            id="documentProperties"
                            class="secondaryToolbarButton documentProperties"
                            title="Document Properties…"
                            tabindex="68"
                            data-l10n-id="document_properties"
                        >
                            <span data-l10n-id="document_properties_label"
                                >Document Properties…</span
                            >
                        </button>
                    </div>
                </div>
                <!-- secondaryToolbar -->

                <div class="toolbar">
                    <div id="toolbarContainer">
                        <div id="toolbarViewer">
                            <div id="toolbarViewerLeft">
                                <button
                                    id="sidebarToggle"
                                    class="toolbarButton"
                                    title="Toggle Sidebar"
                                    tabindex="11"
                                    data-l10n-id="toggle_sidebar"
                                    aria-expanded="false"
                                    aria-controls="sidebarContainer"
                                >
                                    <span data-l10n-id="toggle_sidebar_label"
                                        >Toggle Sidebar</span
                                    >
                                </button>
                                <div class="toolbarButtonSpacer"></div>
                                <div class="splitToolbarButton hiddenSmallView">
                                    <button
                                        class="toolbarButton pageUp"
                                        title="Previous Page"
                                        id="previous"
                                        tabindex="13"
                                        data-l10n-id="previous"
                                    >
                                        <span data-l10n-id="previous_label"
                                            >Previous</span
                                        >
                                    </button>
                                    <div
                                        class="splitToolbarButtonSeparator"
                                    ></div>
                                    <button
                                        class="toolbarButton pageDown"
                                        title="Next Page"
                                        id="next"
                                        tabindex="14"
                                        data-l10n-id="next"
                                    >
                                        <span data-l10n-id="next_label"
                                            >Next</span
                                        >
                                    </button>
                                </div>
                                <input
                                    type="number"
                                    id="pageNumber"
                                    class="toolbarField pageNumber"
                                    title="Page"
                                    value="1"
                                    size="4"
                                    min="1"
                                    tabindex="15"
                                    data-l10n-id="page"
                                    autocomplete="off"
                                />
                                <span id="numPages" class="toolbarLabel"></span>
                            </div>
                            <div id="toolbarViewerRight">
                                <button
                                    id="viewFind"
                                    class="toolbarButton"
                                    title="Find in Document"
                                    tabindex="12"
                                    data-l10n-id="findbar"
                                    aria-expanded="false"
                                    aria-controls="findbar"
                                >
                                    <span data-l10n-id="findbar_label"
                                        >Find</span
                                    >
                                </button>

                                <button
                                    id="presentationMode"
                                    class="toolbarButton presentationMode hiddenLargeView"
                                    title="Full Screen"
                                    tabindex="31"
                                    data-l10n-id="presentation_mode"
                                >
                                    <span data-l10n-id="presentation_mode_label"
                                        >Full Screen</span
                                    >
                                </button>

                                <button
                                    style="display: none;"
                                    id="openFile"
                                    class="toolbarButton openFile hiddenLargeView"
                                    title="Open File"
                                    tabindex="32"
                                    data-l10n-id="open_file"
                                >
                                    <span data-l10n-id="open_file_label"
                                        >Open</span
                                    >
                                </button>

                                <button
                                    style="display: none;"
                                    id="print"
                                    class="toolbarButton print hiddenMediumView"
                                    title="Print"
                                    tabindex="33"
                                    data-l10n-id="print"
                                >
                                    <span data-l10n-id="print_label"
                                        >Print</span
                                    >
                                </button>

                                <button
                                    style="display: none;"
                                    id="download"
                                    class="toolbarButton download hiddenMediumView"
                                    title="Download"
                                    tabindex="34"
                                    data-l10n-id="download"
                                >
                                    <span data-l10n-id="download_label"
                                        >Download</span
                                    >
                                </button>
                                <a
                                    style="display: none;"
                                    href="#"
                                    id="viewBookmark"
                                    class="toolbarButton bookmark hiddenSmallView"
                                    title="Current view (copy or open in new window)"
                                    tabindex="35"
                                    data-l10n-id="bookmark"
                                >
                                    <span data-l10n-id="bookmark_label"
                                        >Current View</span
                                    >
                                </a>

                                <div
                                    class="verticalToolbarSeparator hiddenSmallView"
                                ></div>

                                <button
                                    id="secondaryToolbarToggle"
                                    class="toolbarButton"
                                    title="Tools"
                                    tabindex="36"
                                    data-l10n-id="tools"
                                    aria-expanded="false"
                                    aria-controls="secondaryToolbar"
                                >
                                    <span data-l10n-id="tools_label"
                                        >Tools</span
                                    >
                                </button>
                            </div>
                            <div id="toolbarViewerMiddle">
                                <div class="splitToolbarButton">
                                    <button
                                        id="zoomOut"
                                        class="toolbarButton zoomOut"
                                        title="Zoom Out"
                                        tabindex="21"
                                        data-l10n-id="zoom_out"
                                    >
                                        <span data-l10n-id="zoom_out_label"
                                            >Zoom Out</span
                                        >
                                    </button>
                                    <div
                                        class="splitToolbarButtonSeparator"
                                    ></div>
                                    <button
                                        id="zoomIn"
                                        class="toolbarButton zoomIn"
                                        title="Zoom In"
                                        tabindex="22"
                                        data-l10n-id="zoom_in"
                                    >
                                        <span data-l10n-id="zoom_in_label"
                                            >Zoom In</span
                                        >
                                    </button>
                                </div>
                                <span
                                    id="scaleSelectContainer"
                                    class="dropdownToolbarButton"
                                >
                                    <select
                                        id="scaleSelect"
                                        title="Zoom"
                                        tabindex="23"
                                        data-l10n-id="zoom"
                                    >
                                        <option
                                            id="pageAutoOption"
                                            title=""
                                            value="auto"
                                            selected="selected"
                                            data-l10n-id="page_scale_auto"
                                            >Automatic Zoom</option
                                        >
                                        <option
                                            id="pageActualOption"
                                            title=""
                                            value="page-actual"
                                            data-l10n-id="page_scale_actual"
                                            >Actual Size</option
                                        >
                                        <option
                                            id="pageFitOption"
                                            title=""
                                            value="page-fit"
                                            data-l10n-id="page_scale_fit"
                                            >Page Fit</option
                                        >
                                        <option
                                            id="pageWidthOption"
                                            title=""
                                            value="page-width"
                                            data-l10n-id="page_scale_width"
                                            >Page Width</option
                                        >
                                        <option
                                            id="customScaleOption"
                                            title=""
                                            value="custom"
                                            disabled="disabled"
                                            hidden="true"
                                        ></option>
                                        <option
                                            title=""
                                            value="0.5"
                                            data-l10n-id="page_scale_percent"
                                            data-l10n-args='{ "scale": 50 }'
                                            >50%</option
                                        >
                                        <option
                                            title=""
                                            value="0.75"
                                            data-l10n-id="page_scale_percent"
                                            data-l10n-args='{ "scale": 75 }'
                                            >75%</option
                                        >
                                        <option
                                            title=""
                                            value="1"
                                            data-l10n-id="page_scale_percent"
                                            data-l10n-args='{ "scale": 100 }'
                                            >100%</option
                                        >
                                        <option
                                            title=""
                                            value="1.25"
                                            data-l10n-id="page_scale_percent"
                                            data-l10n-args='{ "scale": 125 }'
                                            >125%</option
                                        >
                                        <option
                                            title=""
                                            value="1.5"
                                            data-l10n-id="page_scale_percent"
                                            data-l10n-args='{ "scale": 150 }'
                                            >150%</option
                                        >
                                        <option
                                            title=""
                                            value="2"
                                            data-l10n-id="page_scale_percent"
                                            data-l10n-args='{ "scale": 200 }'
                                            >200%</option
                                        >
                                        <option
                                            title=""
                                            value="3"
                                            data-l10n-id="page_scale_percent"
                                            data-l10n-args='{ "scale": 300 }'
                                            >300%</option
                                        >
                                        <option
                                            title=""
                                            value="4"
                                            data-l10n-id="page_scale_percent"
                                            data-l10n-args='{ "scale": 400 }'
                                            >400%</option
                                        >
                                    </select>
                                </span>
                            </div>
                        </div>
                        <div id="loadingBar">
                            <div class="progress">
                                <div class="glimmer"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref="pdfViewerContainer" id="viewerContainer" tabindex="0">
                    <div id="viewer" class="pdfViewer"></div>
                </div>

                <div id="errorWrapper" hidden="true">
                    <div id="errorMessageLeft">
                        <span id="errorMessage"></span>
                        <button
                            id="errorShowMore"
                            data-l10n-id="error_more_info"
                        >
                            More Information
                        </button>
                        <button
                            id="errorShowLess"
                            data-l10n-id="error_less_info"
                            hidden="true"
                        >
                            Less Information
                        </button>
                    </div>
                    <div id="errorMessageRight">
                        <button id="errorClose" data-l10n-id="error_close">
                            Close
                        </button>
                    </div>
                    <div class="clearBoth"></div>
                    <textarea
                        id="errorMoreInfo"
                        hidden="true"
                        readonly="readonly"
                    ></textarea>
                </div>
            </div>
            <!-- mainContainer -->

            <div id="overlayContainer" class="hidden">
                <div id="passwordOverlay" class="container hidden">
                    <div class="dialog">
                        <div class="row">
                            <p id="passwordText" data-l10n-id="password_label">
                                Enter the password to open this PDF file:
                            </p>
                        </div>
                        <div class="row">
                            <input
                                type="password"
                                id="password"
                                class="toolbarField"
                            />
                        </div>
                        <div class="buttonRow">
                            <button id="passwordCancel" class="overlayButton">
                                <span data-l10n-id="password_cancel"
                                    >Cancel</span
                                >
                            </button>
                            <button id="passwordSubmit" class="overlayButton">
                                <span data-l10n-id="password_ok">OK</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="documentPropertiesOverlay" class="container hidden">
                    <div class="dialog">
                        <div class="row">
                            <span data-l10n-id="document_properties_file_name"
                                >File name:</span
                            >
                            <p id="fileNameField">-</p>
                        </div>
                        <div class="row">
                            <span data-l10n-id="document_properties_file_size"
                                >File size:</span
                            >
                            <p id="fileSizeField">-</p>
                        </div>
                        <div class="separator"></div>
                        <div class="row">
                            <span data-l10n-id="document_properties_title"
                                >Title:</span
                            >
                            <p id="titleField">-</p>
                        </div>
                        <div class="row">
                            <span data-l10n-id="document_properties_author"
                                >Author:</span
                            >
                            <p id="authorField">-</p>
                        </div>
                        <div class="row">
                            <span data-l10n-id="document_properties_subject"
                                >Subject:</span
                            >
                            <p id="subjectField">-</p>
                        </div>
                        <div class="row">
                            <span data-l10n-id="document_properties_keywords"
                                >Keywords:</span
                            >
                            <p id="keywordsField">-</p>
                        </div>
                        <div class="row">
                            <span
                                data-l10n-id="document_properties_creation_date"
                                >Creation Date:</span
                            >
                            <p id="creationDateField">-</p>
                        </div>
                        <div class="row">
                            <span
                                data-l10n-id="document_properties_modification_date"
                                >Modification Date:</span
                            >
                            <p id="modificationDateField">-</p>
                        </div>
                        <div class="row">
                            <span data-l10n-id="document_properties_creator"
                                >Creator:</span
                            >
                            <p id="creatorField">-</p>
                        </div>
                        <div class="separator"></div>
                        <div class="row">
                            <span data-l10n-id="document_properties_producer"
                                >PDF Producer:</span
                            >
                            <p id="producerField">-</p>
                        </div>
                        <div class="row">
                            <span data-l10n-id="document_properties_version"
                                >PDF Version:</span
                            >
                            <p id="versionField">-</p>
                        </div>
                        <div class="row">
                            <span data-l10n-id="document_properties_page_count"
                                >Page Count:</span
                            >
                            <p id="pageCountField">-</p>
                        </div>
                        <div class="row">
                            <span data-l10n-id="document_properties_page_size"
                                >Page Size:</span
                            >
                            <p id="pageSizeField">-</p>
                        </div>
                        <div class="separator"></div>
                        <div class="row">
                            <span data-l10n-id="document_properties_linearized"
                                >Fast Web View:</span
                            >
                            <p id="linearizedField">-</p>
                        </div>
                        <div class="buttonRow">
                            <button
                                id="documentPropertiesClose"
                                class="overlayButton"
                            >
                                <span data-l10n-id="document_properties_close"
                                    >Close</span
                                >
                            </button>
                        </div>
                    </div>
                </div>
                <div id="printServiceOverlay" class="container hidden">
                    <div class="dialog">
                        <div class="row">
                            <span data-l10n-id="print_progress_message"
                                >Preparing document for printing…</span
                            >
                        </div>
                        <div class="row">
                            <progress value="0" max="100"></progress>
                            <span
                                data-l10n-id="print_progress_percent"
                                data-l10n-args='{ "progress": 0 }'
                                class="relative-progress"
                                >0%</span
                            >
                        </div>
                        <div class="buttonRow">
                            <button id="printCancel" class="overlayButton">
                                <span data-l10n-id="print_progress_close"
                                    >Cancel</span
                                >
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LoadingIndicator v-if="showLoadingIndicator" />
    </div>
</template>

<script>
import * as pdfjs from "pdfjs-dist/webpack";
import {
    PDFViewer,
    EventBus,
    PDFLinkService,
    PDFFindController,
} from "pdfjs-dist/web/pdf_viewer.js";
import { PDFViewerApplication } from "./edited_app";
import { AppOptions } from "pdfjs-dist/lib/web/app_options.js";
import * as services from "./genericcom";
import { GenericExternalServices } from "./genericcom";
import "pdfjs-dist/web/pdf_viewer.css";
import printJS from "print-js";
import { getAbsoluteDomain, isRelativeUrl } from "@/utility";
import LoadingIndicator from "@/components/loadingIndicator/LoadingIndicator.vue";

//All the viewere will share the even bus
function createViewerScaffold(container) {
    const eventBus = new EventBus();
    const linkService = new PDFLinkService({ eventBus });
    const findController = new PDFFindController({ eventBus, linkService });

    /***
     * NOTE: Can add more stuff here
     */
    const viewer = new PDFViewer({
        container,
        eventBus,
        linkService,
        findController,
    });

    linkService.setViewer(viewer);
    return {
        eventBus,
        linkService,
        findController,
        viewer,
    };
}

/**
 * Setup some defualt options
 */
/*
if(true || process.env.NODE_ENV == 'development-local' || process.env.NODE_ENV == 'development' ){
  AppOptions.set('workerSrc', '/pdf/local/pdf.worker.js')
  AppOptions.set('sandboxBundleSrc', '/pdf/local/pdf.sandbox.js')
} else {
  AppOptions.set('workerSrc', '/pdf/pdf.worker.js')
  AppOptions.set('sandboxBundleSrc', '/pdf/pdf.sandbox.js')
}
*/
AppOptions.set("workerSrc", "/pdf/pdf.worker.js");
AppOptions.set("sandboxBundleSrc", "/pdf/pdf.sandbox.js");

AppOptions.set("disablePreferences", true);

export default {
    name: "pdf-viewer",
    props: {
        fileUrl: String,
        page: Number,
        viewerOpts: Object, //Additional options for pdfjs viewer
    },
    components: {
        LoadingIndicator,
    },
    data() {
        return {
            currentPage: null,
            pdf: null,
            pages: [],
            loading: false,
            page_media: null,
            url: null,
            showLoadingIndicator: false,
        };
    },
    watch: {
        currentPage(nv) {
            this.$emit("pageChanged", nv);
        },
        "$route.hash"() {
            PDFViewerApplication.close();
            this.url = this.fileUrl;
            //if(isRelativeUrl(this.fileUrl)) this.url = getAbsoluteDomain(this.fileUrl)
            this.load(this.url);
        },
    },
    created() {
        //Page is set to th
        this.currentPage = this.page || 0;
        this._pdf = null; //The pdf document
    },
    async mounted() {
        //We are mounted so we can start the work of loading the pdf
        await this.$nextTick(); //Wait for children to be mounted too (And in turn refs are available)
        //@Todo Update this
        this.url = this.fileUrl;

        if (isRelativeUrl(this.fileUrl))
            this.url = getAbsoluteDomain(this.fileUrl);
        this.load(this.url);
    },
    async beforeDestroy() {
        if (this._pdf) {
            await PDFViewerApplication.close();
        }
    },
    destroyed() {
        //When this vuew is destroyed
    },
    methods: {
        async load(fileUrl) {
            // If no file url
            if (!fileUrl) return null;
            //Create the viewer scaffolding and
            //Save it in a non reactive variable

            let conf = this.getViewerConfiguration();
            /*
      this._pdf = createViewerScaffold(this.$refs.pdfViewerContainer)
      let {eventBus} = this._pdf
      eventBus.on("pagesinit",function(){
        viewer.currentScaleValue = "page-width"
      })
      */

            if (process.browser)
                window.localStorage.removeItem("pdfjs.history");

            PDFViewerApplication.externalServices = GenericExternalServices;
            PDFViewerApplication.initialize(conf);
            await PDFViewerApplication.initializedPromise;
            console.log("here", conf);
            this.loading = true;
            try {
                await PDFViewerApplication.open(fileUrl);

                // Save the pdf document when we get it

                this.pdf = PDFViewerApplication.pdfDocument;
                console.log("here", this.pdf);
                this.pdfViewer = PDFViewerApplication.pdfViewer;

                this.page_media = document.createElement("style");
                document.head.appendChild(this.page_media);

                PDFViewerApplication.eventBus.on("pagechanging", evt => {
                    this.$emit("currentPageNumber", evt.pageNumber);
                });
                if (this.pdfViewer && this.pdfViewer.currentPageNumber)
                    this.$emit(
                        "currentPageNumber",
                        this.pdfViewer.currentPageNumber
                    );
            } finally {
                this.loading = false;
            }
        },
        //Load the URL from a file
        loadDocument(fileUrl) {
            //@Todo: Make changes needed to load CMAP and CMAP_URL (Not sure what they do yet)
            let doc = pdfjs.getDocument({ url: fileUrl });
            return doc;
        },
        getChildElement(name) {
            /*
      let outerContainer = this.$refs.pdfOuterContainer
      if(name == "outerContainer") return outerContainer;
      */
            return this.$el.querySelector("#" + name);
        },
        getPDFBookmark() {
            const location = PDFViewerApplication?.pdfViewer?._location;
            if (location && location != null) return location;
            return null;
        },
        setDestination() {},
        async printPDFOld() {
            const n_pages = this.pdf?._pdfInfo?.numPages;
            if (this.pdf == undefined || !this.pdf || n_pages == undefined) {
                return;
            }

            let pageCanvases = [];
            let i = 0;

            const changePageSize = size => {
                if (this.page_media)
                    this.page_media.innerHTML =
                        "@page {margin: 0; size: " + size + "}";
            };

            const getPaperType = async () => {
                const page = await this.pdf.getPage(1);
                if (page) {
                    let size = page.view.slice(2);
                    // If the pdf has any normal page sizes, the printer defaults
                    // to them.
                    if (size[0] == 612 && size[1] == 792) {
                        // Page size is 'letter'.
                        changePageSize("letter");
                    } else if (size[0] == 595 && size[1] == 842) {
                        // Page size is 'A4'.
                        changePageSize("A4");
                    } else if (size[0] == 612 && size[1] == 1008) {
                        // Page size is 'legal'.
                        changePageSize("legal");
                    } else if (size[0] == 842 && size[1] == 1190) {
                        // Page size is 'A3'.
                        changePageSize("A4");
                    } else {
                        // The page size isn't known, let the user change the paper size
                        // at their disgression.
                        changePageSize("auto");
                    }
                }
            };
            await getPaperType();

            const renderPrintPage = async (pageNum, canvas, callback) => {
                if (this.pdf == undefined || !this.pdf || !canvas) {
                    return;
                }
                const page = await this.pdf.getPage(pageNum);

                canvas.width = document.body.clientWidth;
                if (page) {
                    let scale = page.view[3] / 300;
                    let viewport = page.getViewport({ scale: scale });

                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    await page.render({
                        canvasContext: canvas.getContext("2d"),
                        viewport: viewport,
                    }).promise;
                    if (callback) callback();
                }
            };

            const generatePage = async pageNum => {
                let pageCanvas = document.createElement("canvas");
                pageCanvas.classList.add("print-canvas");
                document.body.appendChild(pageCanvas);
                await renderPrintPage(pageNum, pageCanvas);
                return pageCanvas;
            };
            let promiseList = [];
            for (let i = 1; i <= n_pages; i++) {
                promiseList.push(generatePage(i));
            }

            const pages = await Promise.all(promiseList);
            for (let i = 0; i < pages.length; i++) {
                pageCanvases.push(pages[i]);
            }
            /* print */
            print();

            // Cleanup
            for (let j = 0; j < pageCanvases.length; j++) {
                document.body.removeChild(pageCanvases[j]);
            }
        },
        async printPDF(filename) {
            console.log(this.url);
            if (this.url) {
                try {
                    this.showLoadingIndicator = true;
                    const req = await fetch(this.url, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/pdf",
                        },
                    });
                    if (req) {
                        const data = await req.blob();
                        if (data) {
                            let url = window.URL.createObjectURL(data);
                            await printJS({
                                printable: url,
                                type: "pdf",
                                documentTitle: filename || "freshmanuals",
                            });
                        } else
                            console.error(
                                "ERROR, BAD RESPONSE FROM: ",
                                this.url
                            );
                    } else console.error("ERROR, NO RESPONSE FROM: ", this.url);
                } catch (e) {
                    console.error("ERROR WITH PRINTJS - ", e);
                }
            }
            this.showLoadingIndicator = false;
        },
        getViewerConfiguration() {
            let errorWrapper = null;
            if (
                typeof PDFJSDev === "undefined" ||
                !PDFJSDev.test("MOZCENTRAL")
            ) {
                errorWrapper = {
                    container: this.getChildElement("errorWrapper"),
                    errorMessage: this.getChildElement("errorMessage"),
                    closeButton: this.getChildElement("errorClose"),
                    errorMoreInfo: this.getChildElement("errorMoreInfo"),
                    moreInfoButton: this.getChildElement("errorShowMore"),
                    lessInfoButton: this.getChildElement("errorShowLess"),
                };
            }

            return {
                appContainer: this.$el,
                mainContainer: this.getChildElement("viewerContainer"),
                viewerContainer: this.getChildElement("viewer"),
                eventBus: null,
                toolbar: {
                    container: this.getChildElement("toolbarViewer"),
                    numPages: this.getChildElement("numPages"),
                    pageNumber: this.getChildElement("pageNumber"),
                    scaleSelectContainer: this.getChildElement(
                        "scaleSelectContainer"
                    ),
                    scaleSelect: this.getChildElement("scaleSelect"),
                    customScaleOption: this.getChildElement(
                        "customScaleOption"
                    ),
                    previous: this.getChildElement("previous"),
                    next: this.getChildElement("next"),
                    zoomIn: this.getChildElement("zoomIn"),
                    zoomOut: this.getChildElement("zoomOut"),
                    viewFind: this.getChildElement("viewFind"),
                    openFile: this.getChildElement("openFile"),
                    print: this.getChildElement("print"),
                    presentationModeButton: this.getChildElement(
                        "presentationMode"
                    ),
                    download: this.getChildElement("download"),
                    viewBookmark: this.getChildElement("viewBookmark"),
                },
                secondaryToolbar: {
                    toolbar: this.getChildElement("secondaryToolbar"),
                    toggleButton: this.getChildElement(
                        "secondaryToolbarToggle"
                    ),
                    toolbarButtonContainer: this.getChildElement(
                        "secondaryToolbarButtonContainer"
                    ),
                    presentationModeButton: this.getChildElement(
                        "secondaryPresentationMode"
                    ),
                    openFileButton: this.getChildElement("secondaryOpenFile"),
                    printButton: this.getChildElement("secondaryPrint"),
                    downloadButton: this.getChildElement("secondaryDownload"),
                    viewBookmarkButton: this.getChildElement(
                        "secondaryViewBookmark"
                    ),
                    firstPageButton: this.getChildElement("firstPage"),
                    lastPageButton: this.getChildElement("lastPage"),
                    pageRotateCwButton: this.getChildElement("pageRotateCw"),
                    pageRotateCcwButton: this.getChildElement("pageRotateCcw"),
                    cursorSelectToolButton: this.getChildElement(
                        "cursorSelectTool"
                    ),
                    cursorHandToolButton: this.getChildElement(
                        "cursorHandTool"
                    ),
                    scrollVerticalButton: this.getChildElement(
                        "scrollVertical"
                    ),
                    scrollHorizontalButton: this.getChildElement(
                        "scrollHorizontal"
                    ),
                    scrollWrappedButton: this.getChildElement("scrollWrapped"),
                    spreadNoneButton: this.getChildElement("spreadNone"),
                    spreadOddButton: this.getChildElement("spreadOdd"),
                    spreadEvenButton: this.getChildElement("spreadEven"),
                    documentPropertiesButton: this.getChildElement(
                        "documentProperties"
                    ),
                },
                sidebar: {
                    // Divs (and sidebar button)
                    outerContainer: this.getChildElement("outerContainer"),
                    viewerContainer: this.getChildElement("viewerContainer"),
                    toggleButton: this.getChildElement("sidebarToggle"),
                    // Buttons
                    thumbnailButton: this.getChildElement("viewThumbnail"),
                    outlineButton: this.getChildElement("viewOutline"),
                    attachmentsButton: this.getChildElement("viewAttachments"),
                    layersButton: this.getChildElement("viewLayers"),
                    // Views
                    thumbnailView: this.getChildElement("thumbnailView"),
                    outlineView: this.getChildElement("outlineView"),
                    attachmentsView: this.getChildElement("attachmentsView"),
                    layersView: this.getChildElement("layersView"),
                    // View-specific options
                    outlineOptionsContainer: this.getChildElement(
                        "outlineOptionsContainer"
                    ),
                    currentOutlineItemButton: this.getChildElement(
                        "currentOutlineItem"
                    ),
                },
                sidebarResizer: {
                    outerContainer: this.getChildElement("outerContainer"),
                    resizer: this.getChildElement("sidebarResizer"),
                },
                findBar: {
                    bar: this.getChildElement("findbar"),
                    toggleButton: this.getChildElement("viewFind"),
                    findField: this.getChildElement("findInput"),
                    highlightAllCheckbox: this.getChildElement(
                        "findHighlightAll"
                    ),
                    caseSensitiveCheckbox: this.getChildElement(
                        "findMatchCase"
                    ),
                    entireWordCheckbox: this.getChildElement("findEntireWord"),
                    findMsg: this.getChildElement("findMsg"),
                    findResultsCount: this.getChildElement("findResultsCount"),
                    findPreviousButton: this.getChildElement("findPrevious"),
                    findNextButton: this.getChildElement("findNext"),
                },
                passwordOverlay: {
                    overlayName: "passwordOverlay",
                    container: this.getChildElement("passwordOverlay"),
                    label: this.getChildElement("passwordText"),
                    input: this.getChildElement("password"),
                    submitButton: this.getChildElement("passwordSubmit"),
                    cancelButton: this.getChildElement("passwordCancel"),
                },
                documentProperties: {
                    overlayName: "documentPropertiesOverlay",
                    container: this.getChildElement(
                        "documentPropertiesOverlay"
                    ),
                    closeButton: this.getChildElement(
                        "documentPropertiesClose"
                    ),
                    fields: {
                        fileName: this.getChildElement("fileNameField"),
                        fileSize: this.getChildElement("fileSizeField"),
                        title: this.getChildElement("titleField"),
                        author: this.getChildElement("authorField"),
                        subject: this.getChildElement("subjectField"),
                        keywords: this.getChildElement("keywordsField"),
                        creationDate: this.getChildElement("creationDateField"),
                        modificationDate: this.getChildElement(
                            "modificationDateField"
                        ),
                        creator: this.getChildElement("creatorField"),
                        producer: this.getChildElement("producerField"),
                        version: this.getChildElement("versionField"),
                        pageCount: this.getChildElement("pageCountField"),
                        pageSize: this.getChildElement("pageSizeField"),
                        linearized: this.getChildElement("linearizedField"),
                    },
                },
                errorWrapper,
                printContainer: this.getChildElement("printContainer"),
                openFileInputName: "fileInput",
                debuggerScriptPath: "./debugger.js",
            };
        },
    },
};
</script>

<style src="./pdfViewer.css"></style>
