<template>
    <div id="root">
        <div id="main-wrapper">
            <div id="main-header">
                <SearchablesHeader
                    :mobile="isPortable"
                    :currentQuery="query"
                    @inputSubmit="onInputSubmit"
                />
            </div>
            <div id="main-body">
                <div class="main-item-bar-outer-wrapper">
                    <ItemFilters
                        v-if="availableFilters"
                        :filterData="availableFilters"
                        :currentlySelected="currentlySelectedFilters"
                        :key="filterKey"
                        @filterChanged="onFilterChange"
                    />
                    <Transition name="fadeSingle" mode="out-in">
                        <div
                            v-if="showDetail"
                            class="back-btn"
                            @click="showDetail = false"
                        >
                            <i class="pi pi-arrow-left"></i> Back
                        </div>
                    </Transition>
                </div>
                <Transition name="fade" mode="out-in">
                    <Detail
                        v-if="showDetail && manuals && !isPortable"
                        :manuals="manuals"
                        :currentManual="currentManual"
                    />
                    <Serp
                        v-else-if="enteredQuery"
                        :manuals="manuals"
                        :currentQuery="enteredQuery"
                        @queryChanged="onQueryChange"
                        @manualClicked="onManualClick"
                    />
                </Transition>
            </div>
            <div id="main-footer">
                <SearchablesFooter :mobile="isPortable" />
            </div>
        </div>
    </div>
</template>

<script>
import SearchablesHeader from "@/components/header/SearchablesHeader.vue";
import SearchablesFooter from "@/components/footer/SearchablesFooter.vue";
import ItemFilters from "@/components/itemFilters/ItemFilters.vue";
import Serp from "./views/serp/Serp.vue";
import Detail from "./views/detail/Detail.vue";

export default {
    name: "App",
    components: {
        SearchablesHeader,
        SearchablesFooter,
        ItemFilters,
        Serp,
        Detail,
    },
    data() {
        const qs = new URLSearchParams(window.location.search);
        return {
            showDetail: false,
            query: qs.get("q") || null,
            enteredQuery: null,
            manualSearchRes: null,
            bookmarks: null,
            searchData: null,
            currentManual: null,
            perPageNo: 60,
            currentlySelectedFilters: {},
            filterKey: 0,
        };
    },
    mounted() {
        this.runCleanSearch(this.query, true);
    },
    computed: {
        isPortable() {
            return this.mediaQuery.isPortable;
        },
        isManualsQuery() {
            if (this.enteredQuery) {
                // Logic to determine if to search for manuals here
                return this.enteredQuery.toLowerCase().includes("manual");
            }
            return false;
        },
        manuals() {
            if (
                this.manualSearchRes &&
                this.manualSearchRes?.main?.records != undefined
            )
                return this.manualSearchRes.main.records;
            return null;
        },
        availableFilters() {
            if (this.manualSearchRes && this.manualSearchRes.aggregations) {
                const agg = this.manualSearchRes.aggregations;
                const filters = [];
                if (agg?.brand?.buckets?.length != undefined)
                    filters.push({
                        name: "Brands",
                        var: "brand",
                        data: agg.brand.buckets,
                    });
                if (agg?.category?.buckets?.length != undefined)
                    filters.push({
                        name: "Categories",
                        var: "category",
                        data: agg.category.buckets,
                    });
                if (agg?.sub_category?.buckets?.length != undefined)
                    filters.push({
                        name: "Sub Categories",
                        var: "sub_category",
                        data: agg.sub_category.buckets,
                    });
                if (agg?.product?.buckets?.length != undefined)
                    filters.push({
                        name: "Products",
                        var: "product",
                        data: agg.product.buckets,
                    });
                if (agg?.year?.buckets?.length != undefined)
                    filters.push({
                        name: "Years",
                        var: "year",
                        data: agg.year.buckets,
                    });

                if (filters.length > 0) return filters;
            }
            return null;
        },
    },
    methods: {
        onManualClick(manual) {
            this.currentManual = manual;

            if (!this.isPortable) {
                // Not mobile/tablet, open details
                this.showDetail = true;
            } else if (manual && manual.url) {
                // mobile/tablet, open directly at source
                window.open(manual.url, "_blank");
            }
        },
        onQueryChange(query) {
            this.query = query;
        },
        onInputSubmit(query) {
            if (query && query != this.enteredQuery) {
                this.currentManual = null;
                this.manuals = null;

                this.runCleanSearch(query);
                this.showDetail = false;
            }
        },
        onFilterChange(newFilters) {
            this.currentlySelectedFilters = newFilters;
            this.runFilteredSearch();
        },
        async runFilteredSearch() {
            const keys = Object.keys(this.currentlySelectedFilters);
            let filters = {};
            if (keys) {
                for (let i in keys) {
                    const key = keys[i];
                    if (
                        Array.isArray(this.currentlySelectedFilters[key]) &&
                        this.currentlySelectedFilters[key].length > 0
                    ) {
                        filters[key] = this.currentlySelectedFilters[key].map(
                            el => el.key
                        );
                    }
                }
            }

            if (this.enteredQuery) {
                const manuals = await this.manualsSearch(
                    this.enteredQuery,
                    Object.keys(filters).length > 0 ? filters : null
                );

                if (manuals?.main?.records != undefined) {
                    this.manualSearchRes = manuals;
                    //this.filterKey++
                } else {
                    this.manualsSearchRes = null;
                }
            }
            this.showDetail = false;
        },
        async runCleanSearch(query, firstSearch = false) {
            console.log("run -------->");
            this.enteredQuery = query;
            this.changeQueryParams(
                { q: this.enteredQuery },
                firstSearch,
                firstSearch ? null : ["yp"]
            );
            if (this.isManualsQuery) {
                this.manuals = null;
                const manuals = await this.manualsSearch(this.enteredQuery);
                if (manuals?.main?.records != undefined) {
                    this.manualSearchRes = manuals;
                } else this.manualsSearchRes = null;
            }
        },
        async manualsSearch(query, filters = null) {
            let facets = {};
            const facetList = [
                "year",
                "brand",
                "category",
                "sub_category",
                "product",
            ];

            for (let i in facetList)
                facets[facetList[i]] = {
                    field: facetList[i],
                    size: 100,
                };

            const body = {
                group: 12,
                q: query,
                perPage: this.perPageNo,
                facets: facets,
            };

            if (filters) body.filters = filters;

            const req = await fetch("/core/v2/search", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            if (req && req.ok) {
                const res = await req.json();
                if (res && res) return res;
            }
            return null;
        },
        changeQueryParams(params, replace = false, removeParams) {
            if (this.$route && this.$router && params) {
                const queryParams = { ...this.$route.query };
                for (let i in params) {
                    queryParams[i] = params[i];
                }

                if (removeParams) {
                    if (!Array.isArray(removeParams))
                        removeParams = [removeParams];
                    for (let i in removeParams) {
                        if (queryParams[removeParams[i]])
                            delete queryParams[removeParams[i]];
                    }
                }

                // Note - catch is purely to prevent incorrect error reporting due to bug in vue router when reloading static assets on the same page
                if (replace)
                    this.$router
                        .replace({ query: queryParams })
                        .catch(() => {});
                else this.$router.push({ query: queryParams }).catch(() => {});
            }
        },
    },
};
</script>

<style src="./main.css"></style>
