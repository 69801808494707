import { createApp } from "vue";
import PrimeVue from "primevue/config";
import App from "./App.vue";
import router from "./router";
import MediaQuery from "./plugins/mediaQuery";
import mediaQueryHelper from "./plugins/mediaQuery/mixin";
import SpeechPlugin from "./plugins/speech/index";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primevue/resources/themes/nova/theme.css";
import "primeicons/primeicons.css";

const app = createApp(App);
app.mixin(mediaQueryHelper);
app.use(router);
app.use(PrimeVue);
app.use(MediaQuery);
app.use(SpeechPlugin);

app.mount("#app");
