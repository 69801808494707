<template>
    <div class="serp-root">
        <div class="serp-outer-wrapper">
            <div v-if="manualsList" class="serp-top">
                <div
                    class="item-list-outer-wrapper"
                    :class="{
                        expandItemList: expandItemList,
                        expandedItemListDesktop: mediaQuery.isDesktop,
                        expandedItemListLaptop: mediaQuery.isLaptop,
                        expandedItemListTablet: mediaQuery.isTablet,
                        expandedItemListMobile: mediaQuery.isMobile,
                    }"
                >
                    <item-list
                        :manuals="manualsList"
                        @manualClicked="onManualClick"
                    />
                </div>
                <div class="item-list-expand-control-wrapper">
                    <Divider
                        v-if="showDivider"
                        class="item-list-expand-control-divider"
                        align="center"
                    >
                        <div class="item-list-expand-control-button-wrapper">
                            <div
                                class="item-list-expand-control-button"
                                @click="toggleItemList()"
                            >
                                <span v-if="expandItemList">
                                    Show Less <i class="pi pi-angle-up"></i>
                                </span>
                                <span v-else>
                                    Show More <i class="pi pi-angle-down"></i>
                                </span>
                            </div>
                        </div>
                    </Divider>
                </div>

                <!-- end Manuals Tile List -->
            </div>
            <div class="serp-bottom">
                <iframe
                    v-if="enteredQuery"
                    id="yhs-iframe"
                    ref="yhsIframe"
                    :src="getFrameSource()"
                    @load="onIframeLoad"
                    class="web-frame"
                ></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import Divider from "primevue/divider";
import ItemList from "../../../../components/itemList/ItemList.vue";

export default {
    name: "Serp",
    props: ["currentQuery", "manuals"],
    components: {
        ItemList,
        Divider,
    },
    data() {
        const qs = new URLSearchParams(window.location.search);
        return {
            perPageNo: 60,
            framePage: qs.get("yp") || 0,
            query: this.currentQuery || null,
            enteredQuery: this.currentQuery || null,
            manualsList: this.manuals || null,
            manualsNumPages: null,
            manualsTotalNum: null,
            manualsCurrentPage: null,
            expandItemList: false,
            typeTag: qs.get("type") || null,
            frameKey: 1,
        };
    },
    watch: {
        manuals(nv) {
            this.manualsList = nv;
        },
        currentQuery(nv) {
            this.enteredQuery = nv;
            this.frameKey = this.frameKey + 1;
        },
    },
    computed: {
        isManualsQuery() {
            if (this.enteredQuery) {
                // Logic to determine if to search for manuals here
                return this.enteredQuery.toLowerCase().includes("manual");
            }
            return false;
        },
        showDivider() {
            if (this.mediaQuery.isMobule) return this.manualsList.length > 2;
            if (this.mediaQuery.isTablet)
                return this.manualsList.length > 4;
            if (this.mediaQuery.isLaptop)
                return this.manualsList.length > 6;
            return this.manualsList.length > 8;
        },
    },
    mounted() {
        this.frameKey++;
        this.$nextTick(() => {
            if (window && window.YHS && window.YHS.init) {
                window.YHS.init({
                    yahoo_domain: "https://search.yahoo.com",
                    iframe_id: "yhs-iframe",
                    debug: false,
                });

                window.YHS.events.on(
                    "heightChanged",
                    "yhsHeightChangeListener",
                    evt => {
                        if (this.$refs && this.$refs.yhsIframe && evt.height) {
                            this.$refs.yhsIframe.style.height = `${evt.height}px`;
                        }
                    }
                );

                window.YHS.events.on(
                    "queryChanged",
                    "yhsQueryChangedListener",
                    evt => {
                        if (this.$refs && this.$refs.header && evt.query)
                            this.$emit(
                                "onQueryChange",
                                decodeURIComponent(evt.query)
                            );
                        if (this.$route && this.$router && evt.query) {
                            const queryParams = { ...this.$route.query };
                            queryParams.q = decodeURIComponent(evt.query);

                            // Note - catch is purely to prevent incorrect error reporting due to bug in vue router when reloading static assets on the same page

                            this.$router
                                .push({ query: queryParams })
                                .catch(() => {});

                            // Emit new Query
                            this.$emit("queryChange", evtQuery);
                            // Do we want to run a new manuals search here?
                        }
                    }
                );

                window.YHS.events.on(
                    "pageNavigated",
                    "yhsPageNavigatedListener",
                    evt => {
                        if (evt && evt.page_url) {
                            const url = new URL(evt.page_url);
                            const resultStartNumber = url.searchParams.get("b");
                            if (
                                resultStartNumber &&
                                this.$route &&
                                this.$router
                            ) {
                                const queryParams = { ...this.$route.query };
                                queryParams.yp = resultStartNumber;
                                // Note - catch is purely to prevent incorrect error reporting due to bug in vue router when reloading static assets on the same page

                                this.$router
                                    .push({ query: queryParams })
                                    .catch(() => {});
                            }
                        }
                    }
                );
            }
        });
    },
    methods: {
        onManualClick(manual) {
            this.$emit("manualClicked", manual);
        },
        toggleItemList(forceState = false) {
            if (forceState) this.expandItemList = forceState;
            else this.expandItemList = !this.expandItemList;
        },
        getFrameSource() {
            const url = new URL(
                "https://search.yahoo.com/yhs/search?hspart=tro&hsimp=yhs-freshy_omni"
            );
            url.searchParams.set("p", this.enteredQuery);
            url.searchParams.set("type", this.typeTag);
            if (this.framePage) url.searchParams.set("b", this.framePage);

            return url.toString();
        },
        onIframeLoad() {
            this.goToTop();
        },
        goToTop() {
            // document.getElementById("root").scrollTo(0, 0);
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style src="./serp.css"></style>
