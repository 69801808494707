function getTopDomain() {
    if (window.location.href) {
        try {
            const url = new URL(window.location.href);
            if (url.origin) return url.origin;
        } catch {}
    }
    return null;
}

function getAbsoluteDomain(path) {
    if (getTopDomain()) {
        if (path && path.charAt(0) == "/") return getTopDomain() + path;
        else return getTopDomain() + "/" + path;
    }
}

function isRelativeUrl(url) {
    const check = /^https?:\/\//i;
    return !check.test(url);
}

export { getAbsoluteDomain, isRelativeUrl };
