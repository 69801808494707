<template>
    <div class="header-root">
        <div
            class="header-wrapper"
            :class="{ 'mobile-header-wrapper': mobile }"
        >
            <div class="header-left">
                <img
                    v-if="mobile"
                    class="mobile-header-logo-image"
                    src="/img/icon.png"
                    @click="onLogoClick"
                />
                <svg v-else class="header-logo-image-wrapper">
                    <image
                        class="header-logo-image"
                        xlink:href="/img/logo.svg"
                        @click="onLogoClick"
                    />
                </svg>
            </div>
            <div class="header-right">
                <div class="header-input-wrapper">
                    <div class="header-input-inner-wrapper">
                        <!--
                        <AutoComplete
                            class="header-input"
                            @keyup.enter="onInputSubmit"
                            @keypress.enter="onInputSubmit"
                            v-model="query"
                            field="text"
                            placeholder="Search For Manuals"
                            @complete="getAutocompleteSuggestions"
                            :suggestions="autoCompleteSuggestions"
                            @item-select="onAutocompleteItemSelect"
                        >
                        </AutoComplete> 
                        -->
                        <div
                            id="header-input-inner-wrapper"
                            class="headerInputInnerWrapper"
                            :class="isAutoFill ? 'autofill' : ''"
                            @removeAutoFill="removeAutoFill"
                            @addAutoFill="addAutoFill"
                        >
                            <auto-complete-input
                                :initValue="query"
                                ref="queryInput"
                                id="header-input"
                                class="headerInput"
                                :group="-1"
                                placeholder="Search For Manuals"
                                @submitQuery="onInputSubmit"
                                @selected="onInputSubmit"
                                @return="onInputSubmit"
                                @iconClick="onInputSubmit"
                            ></auto-complete-input>
                        </div>
                    </div>
                </div>
                <div
                    class="header-button-wrapper"
                    :class="{ 'mobile-header-button-wrapper': mobile }"
                >
                    <div
                        class="header-button"
                        ref="submitBtn"
                        @click="onInputSubmit"
                    >
                        <span v-if="mobile"
                            ><i class="mobile-button-icon pi pi-search"></i
                        ></span>
                        <span v-else>Search</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import fetchJsonp from "fetch-jsonp";
import AutoCompleteInput from "../autoCompleteInput/AutoCompleteInput.vue";

export default {
    name: "searchables-header",
    props: ["currentQuery", "mobile"],
    components: {
        AutoComplete,
        AutoCompleteInput,
    },
    data() {
        return {
            query: this.currentQuery || "",
            autoCompleteSuggestions: null,
        };
    },
    mounted() {},
    watch: {
        currentQuery(nv) {
            this.query = nv;
        },
    },
    methods: {
        onInputSubmit(query) {
            this.$emit("inputSubmit", query);
        },
        onLogoClick() {
            this.emit("logoClick");
        },
        async getAutocompleteSuggestions(ev) {
            const term = ev.query;
            const res = await this.manualsAutocompleteSearch(term);
            this.autoCompleteSuggestions = res;
        },
        async manualsAutocompleteSearch(term) {
            const body = {
                group: 12,
                q: term,
                field: "title",
            };

            try {
                /*
                const req = await fetch(`/core/v2/complete`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(body),
                })
        
                if (req && req.status == 200) {
                    const res = await req.json()
            
                    if (res && res.main && res.main.records && res.main.records.length > 0) {
                        return res.main.records
                    }
                }
                */

                const res = await fetchJsonp(
                    `https://us.search.yahoo.com/sugg/gossip/gossip-us-partner?output=jsonp&appid=tig&command=${term}`
                );

                if (!res.ok) {
                    return await res.json().catch(e => ({
                        error: "Bad Response",
                        query: term,
                        results: [],
                    }));
                }
                const data = await res.json();

                // Mimic our indexer results
                const records = data.gossip.results.map(el => ({
                    text: el.key,
                }));
                return records;
            } catch (e) {
                console.error(`Error with autocomplete call \n ${e}`);
            }

            return null;
        },
        onAutocompleteItemSelect(ev) {
            const {value} = ev;
            const {text} = value;
            this.query = text;
            this.onInputSubmit();
        },
    },
};
</script>

<style>
.header-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    height: 78px;
    padding: 5px;
}

.header-left {
    grid-column-start: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 5px;
}

.header-right {
    grid-column-start: 2;

    display: grid;
    grid-template-columns: 1fr auto;
}

.header-logo-image {
    width: 200px;
}

.header-logo-image-wrapper {
    width: 200px;
    height: 65px;
}

.header-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 12px;
}

.header-input-inner-wrapper {
    width: 100%;
    border: 1px solid #999999;
    border-radius: 20px;
}

.header-input {
    padding: 0px 15px;
    height: 30px;
    width: 100%;
    border: none;
    border-radius: 20px;
    font-size: 1.075em;

    outline: none !important;
}

.header-input .p-autocomplete-input {
    width: 100%;
    border: none;
    outline: none !important;
    outline-width: 0 !important;
}

.header-input.p-inputwrapper-focus {
    outline: none !important;
    outline-width: 0 !important;
}

.header-input .p-autocomplete-input:focus {
    outline: none !important;
    outline-width: 0 !important;
}

.header-input-focus {
    outline-width: 0 !important;
    outline: none !important;
}

.header-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
}

.header-button {
    border-radius: 20px;
    background-color: #0857e8;
    color: white;
    padding: 8px;
    margin: 0px 0px 12px 10px;
    width: 100%;
    text-align: center;
}

.headerInputInnerWrapper {
    position: relative;
    z-index: 20;
}

/** Mobile Styling */

.mobile-header-logo-image {
    width: 50px;
    margin-bottom: 10px;
}

.mobile-header-button-wrapper {
    width: 75px;
}

.mobile-button-icon {
    font-weight: bold;
}
/** End Mobile Styling */
</style>
