/**
 *
 * Plugin for using trTracking, bound to Vue.$trTracking
 *
 * Can set default values for "apiUrl" "defaultGuid", "defaultTypetag" and "defaultOption" in plugin options during init,
 * which will set those to be the defaulted values for apiUrl, guid, typetag and option respectively for any events
 * fired. Can also be set programatically at any point through their respective variables
 *
 * NOTE: apiUrl will always default to "/extensions/adtrack.php" if not set
 *
 * Can also set custom actions in plugin options during init under "customActions", whose key should be the name-value of the
 * action and can additionally include a param "requirements" that should contain an array of parameter names required for
 * that actions event to fire correctly, and so that action will fail if the listed values are not present or valid.
 *
 * It is NOT necessary to declare a custom action to use a currently undefined action, but it is useful for additional
 * debugging purposes and to cleanly define requirements for those actions. Also note that the requirements listed are
 * simply those that MUST be present, and that any values submitted beyond that will still be included in the event call
 *
 *
 * Ex of potential options:
 * const options = {
 *      defaultGuid: ...,
 *      customActions: {
 *          myNewActionName: {
 *              requirements: ["customProperty1", "customProperty2", ...]
 *          }
 *      }
 * }
 *
 * Vue.use(trTracking, options)
 *
 * @author Jesse Bernz - Tightrope Interactive
 * @version 1.0.0
 */

import { reactive } from "@vue/reactivity";

const install = function(Vue, options = {}) {
    class MediaQuery {
        constructor(options) {
            this._sm = parseInt(options.sm) || 576;
            this._md = parseInt(options.md) || 768;
            this._lg = parseInt(options.lg) || 992;
            this._xl = parseInt(options.xl) || 1200;

            this.MOBILE = "MOBILE";
            this.TABLET = "TABLET";
            this.LAPTOP = "LAPTOP";
            this.DESKTOP = "DESKTOP";

            this.currentType = this.DESKTOP;
            this.currentWidth = null;

            try {
                const width =
                    window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth;
                this.currentWidth = width;

                if (this.currentWidth <= this._sm)
                    this.currentType = this.MOBILE;
                else if (this.currentWidth <= this._md)
                    this.currentType = this.TABLET;
                else if (this.currentWidth <= this._lg)
                    this.currentType = this.LAPTOP;
                else this.currentType = this.DESKTOP;
            } catch (e) {
                console.error("Cannot get size of window");
            }
        }

        get currentMediaQuery() {
            return {
                width: this.currentWidth,
                type: this.currentType,
            };
        }

        get isMobile() {
            if (this.currentType == this.MOBILE) return true;
            return false;
        }

        get isTablet() {
            if (this.currentType == this.TABLET) return true;
            return false;
        }

        get isLaptop() {
            if (this.currentType == this.LAPTOP) return true;
            return false;
        }

        get isDesktop() {
            if (this.currentType == this.DESKTOP) return true;
            return false;
        }

        get isComputer() {
            if (
                this.currentType == this.DESKTOP ||
                this.currentType == this.LAPTOP
            )
                return true;
            return false;
        }

        get isPortable() {
            if (
                this.currentType == this.MOBILE ||
                this.currentType == this.TABLET
            )
                return true;
            return false;
        }

        getMediaQueryObj() {
            this.update();
            return {
                ...this,
                currentMediaQuery: this.currentMediaQuery,
                isMobile: this.isMobile,
                isTablet: this.isTablet,
                isLaptop: this.isLaptop,
                isDesktop: this.isDesktop,
                isComputer: this.isComputer,
                isPortable: this.isPortable,
            };
        }

        update() {
            this.currentType = this.DESKTOP;
            this.currentWidth = null;

            try {
                const width =
                    window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth;
                this.currentWidth = width;

                if (this.currentWidth <= this._sm)
                    this.currentType = this.MOBILE;
                else if (this.currentWidth <= this._md)
                    this.currentType = this.TABLET;
                else if (this.currentWidth <= this._lg)
                    this.currentType = this.LAPTOP;
                else this.currentType = this.DESKTOP;
            } catch (e) {
                console.error("Cannot get size of window");
            }
        }
    }

    try {
        let app = null;
        const version = parseInt(Vue.version);

        if (version == 3) {
            // Vue 3
            app = Vue.config.globalProperties;
        } else {
            // Vue 2
            app = Vue.prototype;
        }

        app.$mediaQuery = new MediaQuery(options);
        window.addEventListener("resize", () => {
            app.$mediaQuery = new MediaQuery(options);
        });
    } catch (e) {
        console.error("Cannot correctly detect Vue version - ", e);
    }
};

export default { install };
