<template>
    <div class="item-tile-root">
        <div v-if="data" class="item-tile-wrapper" @click="emitClick">
            <div v-if="data.title" class="item-tile-title">
                {{ data.title }}
            </div>
            <div v-if="formattedUrl" class="item-tile-host">
                {{ formattedUrl }}
            </div>
            <div v-if="metadata" class="item-tile-metadata-wrapper">
                <div class="item-tile-metadata-inner-wrapper">
                    <span
                        v-for="(meta, index) in metadata"
                        :key="index"
                        class="item-tile-metadata"
                    >
                        <span class="item-tile-metadata-name">{{
                            meta.name
                        }}</span>
                        -
                        <span class="item-tile-metadata-value">{{
                            meta.value
                        }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "item-tile",
    props: ["data"],
    computed: {
        metadata() {
            if (this.data) {
                const getValue = value => {
                    return Array.isArray(value) ? value[0] : value;
                };

                const meta = [];
                if (this.data.brand)
                    meta.push({
                        name: "Brand",
                        value: getValue(this.data.brand),
                    });
                if (this.data.category)
                    meta.push({
                        name: "Category",
                        value: getValue(this.data.category),
                    });
                if (this.data.sub_category)
                    meta.push({
                        name: "Subcategory",
                        value: getValue(this.data.sub_category),
                    });
                if (this.data.product)
                    meta.push({
                        name: "Product",
                        value: getValue(this.data.product),
                    });
                if (this.data.model)
                    meta.push({
                        name: "Model",
                        value: getValue(this.data.model),
                    });

                if (meta.length > 0) return meta;
            }
            return null;
        },
        formattedUrl() {
            if (this.data && this.data.url) {
                try {
                    const url = new URL(this.data.url);
                    return url.host;
                } catch (e) {
                    console.error(e);
                    return this.data.url;
                }
            } else return null;
        },
    },
    methods: {
        emitClick() {
            this.$emit("tileClick", this.data);
        },
    },
};
</script>

<style>
.item-tile-wrapper {
    width: 100%;
    height: 100%;
    border: 1px solid #999999;
    border-radius: 3px;
    padding: 10px;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto auto 1fr;

    cursor: pointer;
}

.item-tile-title {
    font-weight: bold;
    font-size: 0.9em;
}

.item-tile-host {
    font-size: 0.8em;
    color: #68a74f;
}

.item-tile-metadata-wrapper {
    font-size: 0.7em;
    position: relative;
}

.item-tile-metadata-inner-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
}

.item-tile-metadata {
    padding-right: 10px;
}

.item-tile-metadata-name {
    font-weight: bold;
}
</style>
