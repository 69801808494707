<template>
    <div class="auto-complete-box">
        <div class="input-wrapper">
            <div
                id="input-icon-wrapper"
                class="inputIconWrapper"
                v-if="showIcon"
            >
                <i class="pi pi-search inputIcon"></i>
            </div>
            <input
                ref="input"
                id="auto-complete-input"
                class="autoCompleteInput"
                :class="{ empty: !showIcon }"
                v-model="query"
                @input="handleInputChange($event)"
                :placeholder="placeholder"
                @blur="handleBlur"
                @focus="handleFocus"
                @keypress.enter="handleReturn"
                @keypress="queryChange"
                @keydown.backspace="queryChange"
                @keydown.exact.up="sugUpdate(-1, $event)"
                @keydown.exact.down="sugUpdate(1, $event)"
                autocomplete="off"
            />

            <div
                title="Clear"
                id="clear-icon-wrapper"
                class="inputIconWrapper"
                @click="clearClick()"
            >
                <i class="pi pi-times inputIcon"></i>
            </div>

            <speech-icon @speechResult="onSpeechResult" />

            <div
                title="Search"
                id="static-input-icon-wrapper"
                class="inputIconWrapper"
                @click="handleReturn()"
            >
                <i class="pi pi-search inputIcon"></i>
            </div>
        </div>
        <div class="serp-suggestions" v-show="hasResults && hasFocus && test()">
            <div
                class="serp-suggestion"
                :class="{ active: idx == realSelected }"
                @click="handleClick(s.resultEntity, $event)"
                v-for="(s, idx) in items"
                :key="idx"
            >
                <div class="inputIconWrapperSug">
                    <i class="pi pi-search inputIcon"></i>
                </div>
                <div class="sug-info">
                    <h4 class="sug-title">
                        <span
                            class="hightlight"
                            v-for="(text, ind) in getPart(s.resultEntity, 1)"
                            :key="'b' + ind"
                            >{{ text }}</span
                        >
                        {{ query.trim()
                        }}<span
                            class="hightlight"
                            v-for="(text, ind) in getPart(s.resultEntity, 2)"
                            :key="'a' + ind"
                            >{{ text }}</span
                        >
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash.debounce";
import trim from "lodash.trim";
import fetchJsonp from "fetch-jsonp";

// autocomplete.js stuff
import memoize from "lodash.memoize";

export default {
    props: ["initValue", "placeholder", "group", "dev"],
    data() {
        return {
            results: false,
            selected: false,
            errors: false,
            hasFocus: false,
            issued: false,
            query: "",
            prevQuery: null,
            api_url: "/core/v2",
            suggestionProvider: null,
            clearCache: null,
            showIcon: false,
        };
    },
    mounted() {
        this.suggestionProvider = this.suggestionProviderFunction();
        this.clearCache = this.clearCacheFunction();
        if (this.initValue) this.query = this.initValue;
        this.focus();
    },
    updated() {
        this.focus();
        this.handleFocus();
    },
    watch: {
        group() {
            // Immediately clear the cache whenever the vertical changes
            this.results = null;
            this.prevQuery = null; // resets no sug for same
            this.suggestionProvider.cache.clear();

            // Automatically remove results when group changes

            // If invalid group number, default to -1 / yahoo web suggest
            if (isNaN(this.group)) this.group = -1;
        },
        initValue() {
            this.prevQuery = null;
        },
    },
    methods: {
        test() {
            return this.prevQuery != this.query;
        },
        suggestionProviderFunction() {
            return memoize(async term => {
                this.clearCache(); // Make sure the cache doesnt get too big (clears in 1 minute)
                this.prevQuery = null; // resets no sug for same
                if (this.group == -1) {
                    const res = await fetchJsonp(
                        `https://us.search.yahoo.com/sugg/gossip/gossip-us-partner?output=jsonp&appid=tig&command=${term}`
                    );

                    if (!res.ok) {
                        return await res.json().catch(e => ({
                            error: "Bad Response",
                            query: term,
                            results: [],
                        }));
                    }
                    const data = await res.json();

                    // Mimic our indexer results
                    const records = data.gossip.results.map(el => ({
                        resultEntity: el.key,
                    }));

                    return {
                        records,
                    };
                }
            });
        },
        clearClick() {
            this.$emit("submitQuery", "");
            this.showIcon = false;
        },
        clearCacheFunction() {
            const ONE_MINUTE_MS = 60 * 1000;
            return debounce(
                () => this.suggestionProvider.cache.clear(),
                10 * ONE_MINUTE_MS
            );
        },
        handleInputChange(e) {
            // this.$emit("input", e.target.value);
            if (e.target.value === "") {
                this.$emit("removeAutoFill");
                this.showIcon = false;
            }
        },
        focus() {
            this.$refs.input.focus();
            if (this.query !== "" && this.query !== null) this.showIcon = true;
        },
        blur() {
            this.$refs.input.blur();
        },
        handleBlur() {
            if (this.dev) {
                this.haveFocus = true;
            } else {
                window.setTimeout(() => {
                    this.hasFocus = false;
                    this.selected = -1;
                    this.prevQuery = this.query;
                    this.$emit("removeAutoFill");
                }, 500);
                this.$emit("blur");
            }
            if (!this.query || this.query == "") this.showIcon = false;
        },
        handleFocus() {
            this.$nextTick(() => {
                this.hasFocus = true;
            });
            this.$emit("focus");
        },
        handleReturn() {
            // If something is selected we will pick it.
            if (!this.items || this.items == null)
                this.$emit("submitQuery", this.query);
            if (this.selected !== false && this.items[this.realSelected]) {
                const elt = this.items[this.realSelected].resultEntity;
                this.$emit("submitQuery", elt);
                this.$emit("selected");
            } else {
                this.$emit("return");
            }
            this.$emit("removeAutoFill");
        },
        handleClick(item, e) {
            this.$emit("submitQuery", item);
            this.$emit("selected");
        },
        sugUpdate(count, e) {
            e.preventDefault();
            if (count == false) {
                this.selected = false;
                return;
            }
            if (this.selected === false) {
                this.selected = count < 0 ? -1 : 0;
                return;
            }
            this.selected += count;
        },
        async queryChange(e) {
            if (e.keyCode != 13) {
                if (this.issued) {
                    this.issued = false;
                    this.request(false);
                    return;
                }
                /*
                if (this.results && this.query) {
                    if (this.query.length > this.value.length) {
                        this.clear();
                    }
                }
                */
                await this.request();
                // this.query = this.value;
            } else {
                this.$refs.input.blur();
            }
        },
        clear() {
            this.results = false;
            this.selected = false;
        },
        request: debounce(async function(cancel) {
            const term = trim(this.query);
            const lastResult = this.results;
            if (term === "" || cancel) {
                this.clear();
                return;
            }
            try {
                const res = await this.suggestionProvider(term);
                if (this.query.indexOf(term) == -1) {
                    if (lastResult == this.results) {
                        this.clear();
                    }
                    return;
                }

                this.results = res.records;
                if (this.hasResults) {
                    this.$emit("addAutoFill");
                }
            } catch (e) {
                this.results = [];
            }
        }, 300),
        getPart(text, type) {
            // before

            const i = text.indexOf(this.query.trim());
            if (type == 1)
                if (text.substr(0, i) !== "") return [text.substr(0, i)];
            // after
            if (type == 2) {
                if (text.substr(i + this.query.trim().length) !== "")
                    return [text.substr(i + this.query.trim().length)];
            }
            return [];
        },
        async onSpeechResult(e) {
            if (e) {
                this.$emit("submitQuery", e);
                this.$emit("selected");
                this.$emit("return");
            }
        },
    },
    computed: {
        hasResults() {
            return this.results && this.items.length > 0;
        },
        realSelected() {
            if (this.selected === false) return -1;
            if (this.items === false) return -1;
            if (!this.items) return -1;
            return this.selected % this.items.length;
        },
        items() {
            return this.results && this.results;
        },
    },
};
</script>

<style>
@import "./autocompleteinput.css";
</style>
